// import { useContext } from "react";
import {
  useQuery,
  useQueryClient,
  // useMutation,
  // QueryClient,
  // QueryClientProvider,
} from "@tanstack/react-query";
import axios from "axios";
import config from "utils/config";
import {
  // useAuthUser,
  useAuthHeader,
  useSignOut,
} from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { omit } from "lodash";
import { therapeuticAreasDesign } from "utils/config-design";

export const GetContentFooter = () => {
  return useQuery({
    queryKey: ["footerData"],
    queryFn: async () => {
      const response = await axios.get(`${config.API_URL}/get-footer-data`);
      return response.data;
    },
    refetchOnMount: true,
  });
};

function mergeSubKeys(obj1, obj2) {
  const mergedObj = {};

  for (const key in obj1) {
    if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
      mergedObj[key] = { ...obj1[key], ...obj2[key] };
    } else if (obj1.hasOwnProperty(key)) {
      mergedObj[key] = obj1[key];
    } else {
      mergedObj[key] = obj2[key];
    }
  }

  return mergedObj;
}

export const GetContentTherapeuticAreas = () => {
  const authHeader = useAuthHeader();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signOutAll = async () => {
    // console.log("signOutAll > GetContentTherapeuticAreas");
    signOut();
    queryClient.removeQueries();
    queryClient.clear();
    navigate("/login");
  };

  return useQuery({
    queryKey: ["therapeuticAreasData"],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/get-therapeutic-areas-content`,
          {
            headers: { Authorization: authHeader() },
          }
        );

        const newData = mergeSubKeys(therapeuticAreasDesign, response.data);
        // console.log(
        //   "therapeuticAreasData Query",
        //   therapeuticAreasDesign,
        //   response.data,
        //   newData
        // );
        queryClient.setQueryData(["therapeuticAreasData"], newData);
        return newData;
      } catch (err) {
        // console.log("GetContentTherapeuticAreas", err);
        if (err.response.status === 401) {
          signOutAll();
        }
      }
    },
    refetchOnMount: true,
    staleTime: 1000 * 60, // Data is fresh for 30 seconds
  });
};

export const GetArticles = () => {
  const authHeader = useAuthHeader();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signOutAll = async () => {
    // console.log("signOutAll > GetArticles");
    signOut();
    queryClient.removeQueries();
    queryClient.clear();
    navigate("/login");
  };

  return useQuery({
    queryKey: ["articles"],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/get-articles-content`,
          {
            headers: { Authorization: authHeader() },
          }
        );

        return response.data;
      } catch (err) {
        // console.log("GetArticles", err);
        if (err.response.status === 401) {
          signOutAll();
        }
      }
    },
    // {
    staleTime: 1000 * 60, // Data is fresh for 60 seconds
    // }
    // refetchOnMount: false, // Not needed with refetchOnWindowFocus
    // staleTime: 0,
    // refetchOnWindowFocus: true, // Refetch on window focus (refresh)
    // networkMode: "always",
  });
};

export const GetMedia = (uuid) => {
  const authHeader = useAuthHeader();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signOutAll = async () => {
    // console.log("signOutAll > GetMedia");
    signOut();
    queryClient.removeQueries();
    queryClient.clear();
    navigate("/login");
  };

  return useQuery({
    queryKey: [`media-${uuid}`],
    queryFn: async () => {
      if (!uuid) {
        throw new Error("UUID is null");
      }

      try {
        const response = await axios.get(`${config.API_URL}/assets/${uuid}`, {
          headers: { Authorization: authHeader() },
        });
        return response.data;
        // return therapeuticAreasContent;
      } catch (err) {
        console.log("GetMedia", err);
        if (err.response.status === 401) {
          signOutAll();
        }
      }
    },
    // enabled: !uuid,
    refetchOnMount: true,
    staleTime: Infinity, // Data will not become stale
    cacheTime: Infinity, // Data will be cached indefinitely
  });
};

export const GetEventStatus = (eventSlug) => {
  const authHeader = useAuthHeader();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signOutAll = async () => {
    // console.log("signOutAll > GetEventStatus");
    signOut();
    queryClient.removeQueries();
    queryClient.clear();
    navigate("/login");
  };

  return useQuery({
    queryKey: [`event-${eventSlug}`],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/events/${eventSlug}/status`,
          {
            headers: { Authorization: authHeader() },
          }
        );
        // console.log("GetEventStatus > api response", response.data);
        return omit(response.data, "timestamp");
        // return therapeuticAreasContent;
      } catch (err) {
        // console.log("GetEventStatus", err);
        if (err.response.status === 401) {
          signOutAll();
        }
      }
    },
    refetchInterval: 50 * 1000,
  });
};

export const GetPage = (pageSlug) => {
  const authHeader = useAuthHeader();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const signOutAll = async () => {
    // console.log("signOutAll > GetPage");
    signOut();
    queryClient.removeQueries();
    queryClient.clear();
    navigate("/login");
  };

  return useQuery({
    queryKey: ["page-" + pageSlug],
    queryFn: async () => {
      try {
        const response = await axios.get(
          `${config.API_URL}/pages/${pageSlug}`,
          {
            headers: { Authorization: authHeader() },
          }
        );

        return response.data;
      } catch (err) {
        // console.log("GetArticles", err);
        if (err.response.status === 401) {
          signOutAll();
        }
      }
    },
    // {
    staleTime: 1000 * 60, // Data is fresh for 60 seconds
    // }
    // refetchOnMount: false, // Not needed with refetchOnWindowFocus
    // staleTime: 0,
    // refetchOnWindowFocus: true, // Refetch on window focus (refresh)
    // networkMode: "always",
  });
};
