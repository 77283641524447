import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Container,
  Button,
  Typography,
  Box,
  Grid,
  Stack,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ArrowLongUpIcon,
  XMarkIcon,
  ArrowsPointingOutIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import {
  GetContentTherapeuticAreas,
  GetArticles,
  GetMedia,
} from "utils/api/getContent";
import {
  Icon,
  IconButtonOutlined,
  ButtonExternalLink,
} from "components/common";
import { therapeuticAreasDesign } from "utils/config-design";
import theme from "utils/theme";
import { format } from "date-fns";
import ro from "date-fns/locale/ro";
import JWPlayer from "@jwplayer/jwplayer-react";
import ArticlesSidebar from "components/articles/ArticlesSidebar";
import ArticleContentHTML from "components/articles/ArticleContentHTML";
import ArticleGeneratePDF from "components/articles/ArticleGeneratePDF";
import { articleContentSanitized } from "utils/content";
import useUserTracking from "utils/useTracking";
import Error404 from "pages/Error404";
import appContext from "utils/context";
// import { useUtag } from "hooks/useUtag";

// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// pdfjs.GlobalWorkerOptions.workerSrc = "pdfjs-dist/build/pdf.worker.min.js";
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

const ArticleInfo = () => {
  const {
    therapeuticAreaSlug,
    therapeuticAreaIndicationSlug,
    brandSlug,
    articleSlug,
    contentSlug,
  } = useParams();

  const { userTrack } = useUserTracking({ stop: true });
  // const utag = useUtag();

  const { setIsWhiteContentPage } = useContext(appContext);
  useEffect(() => {
    setIsWhiteContentPage(true);

    return () => {
      setIsWhiteContentPage(false);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const storedArticlesViewed = localStorage.getItem("az-articles");
    const articleURL = window.location.pathname;
    // console.log("articleInfo > storedArticlesViewed", storedArticlesViewed);
    if (storedArticlesViewed) {
      const storedArticlesViewedArray = JSON.parse(storedArticlesViewed);
      // console.log(
      //   "articleInfo > storedArticlesViewedArray",
      //   storedArticlesViewedArray
      // );
      if (!storedArticlesViewedArray.includes(articleURL)) {
        storedArticlesViewedArray.push(articleURL);
        try {
          localStorage.setItem(
            "az-articles",
            JSON.stringify(storedArticlesViewedArray)
          );
        } catch (error) {}
      }
    } else {
      try {
        localStorage.setItem("az-articles", JSON.stringify([articleURL]));
      } catch (error) {}
    }
  }, []);

  // ARTICLE
  const {
    data: therapeuticAreasContent,
    // isErrorArticles,
    // isPendingArticles,
  } = GetArticles();

  const article = therapeuticAreasContent?.articles.find(
    (article) =>
      (article.slug === articleSlug &&
        article.brand_slug === brandSlug &&
        (article.type === "text" ||
          article.type === "video" ||
          article.type === "material")) ||
      null
  );

  useEffect(() => {
    if (article) {
      // console.log("article should track", article);
      userTrack({
        page: articleSlug,
        therapeutic_area: therapeuticAreaSlug,
        therapeutic_area_indication: therapeuticAreaIndicationSlug,
        brand: brandSlug,
        content_type: contentSlug,
        ...(contentSlug === "videos"
          ? {
              video_id: "video-" + articleSlug,
              video_name: article.video_url.split("/").at(-1),
            }
          : {}),
      });
    }
  }, [article]); // eslint-disable-line react-hooks/exhaustive-deps

  const topRef = useRef(null);

  // BRAND
  const {
    data: therapeuticAreasData,
    // isError,
    // isPending,
  } = GetContentTherapeuticAreas();
  const therapeuticArea = therapeuticAreasData
    ? therapeuticAreasData[therapeuticAreaSlug]
    : null;
  const therapeuticAreaIndication = therapeuticArea?.indications.find(
    (indication) => indication.slug === therapeuticAreaIndicationSlug
  );
  const product =
    therapeuticAreaIndication?.brands.find(
      (brand) => brand.slug === brandSlug
    ) || null;

  const ProductButton = ({ label, href }) => {
    return (
      <ButtonExternalLink
        fullWidth
        component={Link}
        href={href}
        target="_blank"
        rel="noreferrer"
        variant="outlined"
        endIcon={
          <Box position="relative">
            <ArrowLongRightIcon width={24} />
            <Box
              sx={{
                position: "absolute",
                width: 18,
                height: 18,
                borderRadius: "50%",
                border: `1px solid ${"secondary.darker"}`,
                top: "50%",
                transform: "translateY(-50%)",
                right: "-4px",
              }}
            />
          </Box>
        }
        sx={{
          flex: 1,
          border: "0.5px solid rgba(255, 255, 255, 0.25)",
          boxShadow: "none",
          background:
            "linear-gradient(180deg, rgba(235, 212, 247, 0.12) 0%, rgba(235, 212, 247, 0.04) 100%)",
          minWidth: 110,
          height: { xs: 32, sm: 38 },
          "& .MuiButton-endIcon": {
            transition: "all 200ms ease-in-out",
            overflow: "hidden",
            width: 0,
          },
          "&:hover": {
            border: "0.5px solid rgba(255, 255, 255, 0.25)",
            background:
              "linear-gradient(180deg, rgba(235, 212, 247, 0.24) 0%, rgba(235, 212, 247, 0.08) 100%)",
            boxShadow: "0px 0px 40px 1px rgba(218, 204, 227, 0.15)",
            backdropFilter: "blur(16px)",
            "& .MuiButton-endIcon": {
              width: 28,
            },
          },
          // color: "#110720",
        }}
      >
        {label}
      </ButtonExternalLink>
    );
  };

  const ArticleBrand = () => {
    // const brandIndications = therapeuticArea.indications.filter(
    //   (indication) =>
    //     indication.brands.find((brand) => brand.slug === brandSlug) !==
    //     undefined
    // );
    // console.log("brandIndications", brandIndications);

    const BrandIndication = ({ indication }) => {
      return (
        <Stack
          component={RouterLink}
          to={`/therapeutic-areas/${therapeuticAreaSlug}/${indication.slug}`}
          direction="row"
          alignItems="stretch"
          sx={{
            borderRadius: "0.25rem",
            border: "0.5px solid rgba(255, 255, 255, 0.25)",
            overflow: "hidden",
            transition: "all 200ms ease-in-out",
            "&:hover": {
              background:
                "linear-gradient(180deg, rgba(235, 212, 247, 0.24) 0%, rgba(235, 212, 247, 0.08) 100%)",
              "& .brandLogo": {
                background: therapeuticAreasDesign[therapeuticAreaSlug].color,
              },
            },
          }}
        >
          <Box
            className="brandLogo"
            sx={{
              padding: "0.2rem 0.45rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              transition: "all 200ms ease-in-out",
              background:
                "linear-gradient(180deg, rgba(235, 212, 247, 0.24) 0%, rgba(235, 212, 247, 0.08) 100%)",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: {
                  xs: 14,
                  sm: 18,
                },
                textAlign: "center",
                "& img": {
                  display: "block",
                },
              }}
            >
              <Icon
                name={`/assets/images/products/white/${product.slug}`}
                fullPath={true}
                height="100%"
                objectFit="contain"
                marginTop={0}
              />
            </Box>
          </Box>
          <Box paddingX={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="body3"
                sx={{ color: "primary.main", textDecoration: "none" }}
              >
                {indication.title}
              </Typography>
              <Box>
                <Icon
                  name={`indicatii/white/${therapeuticAreaIndication.slug}`}
                  width={16}
                  marginTop={0}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      );
    };

    return (
      <>
        <Stack
          direction="row"
          alignItems="stretch"
          gap={{
            xs: 2,
            sm: 4,
          }}
          sx={{ marginTop: 2, marginBottom: 3 }}
        >
          <Box
            component={RouterLink}
            to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}`}
            sx={{
              width: {
                xs: 80,
                sm: 156,
              },
              borderRadius: "0.75rem",
              border: "0.5px solid rgba(248, 248, 248, 0.25)",
              padding: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: {
                  xs: 48,
                  sm: 80,
                },
                textAlign: "center",
                "& img": {
                  display: "block",
                },
              }}
            >
              <Icon
                name={`/assets/images/products/white/${product.slug}`}
                fullPath={true}
                height="100%"
                objectFit="contain"
                marginTop={0}
              />
            </Box>
          </Box>
          <Box paddingY={0.25}>
            <Stack
              alignItems="start"
              justifyContent="space-between"
              height="100%"
            >
              <Box>
                <Stack direction="row" alignItems="center" gap={2}>
                  <BrandIndication indication={therapeuticAreaIndication} />
                </Stack>
              </Box>
              <Box>
                <Stack direction="row" alignItems="center" gap={4}>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    endIcon={<PlusCircleIcon width={20} />}
                    sx={{ paddingX: 3 }}
                  >
                    Urmărește {product.name}
                  </Button> */}
                  {product.rcp_url?.length &&
                    product.rcp_url.map((rcp, index) => (
                      <ProductButton
                        key={`RCP-${index + 1}`}
                        label={
                          rcp.rcp_name
                            ? rcp.rcp_name
                            : product.rcp_url.length > 1
                            ? `RCP ${index + 1}`
                            : "RCP"
                        }
                        href={rcp.url}
                      />
                    ))}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Box
          sx={{
            width: "100%",
            height: "1px",
            marginBottom: 2,
            background:
              "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.56) 50%, rgba(255,255,255,0) 100%)",
          }}
        />
      </>
    );
  };

  const ArticleContent = ({ article }) => {
    // console.log("article", article);
    const getArticleContent = () => {
      return article.content;
    };

    const ArticleVideoPlayer = ({ article }) => {
      const {
        data: articleImage,
        isError,
        isPending,
      } = GetMedia(article.image_id);

      // resume video playback - BEGIN
      const [player, setPlayer] = useState(null);
      const [playerId, setPlayerId] = useState("video-article");
      const [azVideos, setAzVideos] = useState([]);
      const [percentagePlayed, setPercentagePlayed] = useState(null);

      const handleVideoReady = (event) => {
        // console.log("video ready", event);
        const storedData = localStorage.getItem("az-videos");
        if (storedData) {
          const storedDataArray = JSON.parse(storedData);
          // console.log("storedDataArray", storedDataArray);
          setAzVideos(storedDataArray);

          // Check for video data and resume playback if needed
          const videoId = article.video_url;
          const videoData = storedDataArray.find((data) => data.id === videoId);
          if (videoData && videoData.seconds > 10) {
            console.log(
              "we should play the video from ",
              videoData.seconds,
              player
            );
            if (player) {
              // player.pause();
              player.seek(videoData.seconds);
              player.setMute(false);
              // player.play();
            }
          }
        }
      };

      const handleVideoDidMountCallback = (event) => {
        console.log("video handleVideoDidMountCallback", event);
        setPlayer(event.player);
        setPlayerId(event.id);

        // Access the JWPlayer instance from the event
        const playerInstance = event.player;

        // Wait until the player is ready and then access the <video> element
        playerInstance.on("ready", () => {
          const videoElement = document.querySelector(`#${event.id} video`);

          if (videoElement) {
            videoElement.id = "video-" + articleSlug; // Set your desired ID here
          }
        });
      };

      const handleVideoTimeUpdate = (event, isDone = false) => {
        // console.log(event?.position, isDone);

        let azVideosNew = [];

        if (isDone) {
          azVideosNew = [
            ...azVideos.filter(
              (videoPlayed) => videoPlayed.id !== article.video_url
            ),
          ];
        } else {
          azVideosNew = [
            ...azVideos.filter(
              (videoPlayed) => videoPlayed.id !== article.video_url
            ),
            { id: article.video_url, seconds: isDone ? 0 : event.position },
          ];
        }

        setAzVideos(azVideosNew);
        try {
          localStorage.setItem("az-videos", JSON.stringify(azVideosNew));
        } catch (error) {}

        const percentage = Math.floor((event.position / event.duration) * 100);
        if ([15, 25, 35, 50, 75].includes(percentage)) {
          // console.log(event?.position, percentage, isDone);
          if (percentagePlayed !== percentage) {
            // utag.link({
            //   video_id: "video-" + articleSlug,
            //   video_name: article.video_url.split("/").at(-1),
            //   event_name: "video_played_" + percentage,
            // });
            setPercentagePlayed(percentage);
          }
        }

        if (isDone) {
          // utag.link({
          //   video_id: "video-" + articleSlug,
          //   video_name: article.video_url.split("/").at(-1),
          //   event_name: "video_viewed",
          // });
        }
      };
      // resume video playback - END

      const handleVideoPlay = (event) => {
        // console.log("video PLAY", event);
        // utag.link({
        //   video_id: "video-" + articleSlug,
        //   video_name: article.video_url.split("/").at(-1),
        //   event_name: "video_played",
        // });
      };

      return isPending ? (
        <Box textAlign="center">
          <CircularProgress size={20} />
        </Box>
      ) : (
        !isError && (
          <JWPlayer
            id={playerId}
            file={article.video_url}
            image={`data:${articleImage.file_type};base64,${articleImage.content}`}
            library="https://content.jwplatform.com/libraries/ktd5pJhX.js"
            config={{
              key: "en/aXi8ObIYnCZO0QZ8O2fioatD9DEwZkVt4ADV1MgY=",
              primary: "html5",
              hlshtml: true,
              width: "100%",
              autostart: false,
              aspectratio: "16:9",
            }}
            didMountCallback={handleVideoDidMountCallback}
            onBeforePlay={handleVideoReady}
            onTime={handleVideoTimeUpdate}
            onPlay={handleVideoPlay}
            onComplete={(event) => handleVideoTimeUpdate(event, true)}
          />
        )
      );
    };

    const ArticleMaterialImage = ({ imageID }) => {
      const { data: articleImage, isError, isPending } = GetMedia(imageID);
      // console.log(articleImage);

      const [open, setOpen] = useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };

      const handleClose = () => {
        setOpen(false);
      };

      // DOWNLOAD
      const [isDownloading, setIsDownloading] = useState(false);

      const downloadImage = () => {
        setIsDownloading(true);

        // Decode the base64 content (if not already decoded)
        // const base64Image = articleImage.content
        //   ? articleImage.content
        //   : atob(articleImage.content); // Adjust if already decoded

        const buffer = new Buffer(articleImage.content, "base64");
        const blob = new Blob([buffer], { type: articleImage.file_type });

        // Replace URL.createObjectURL with createObjectURL if available
        const url = URL.createObjectURL
          ? URL.createObjectURL(blob)
          : window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = articleImage.file_name;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
          URL.revokeObjectURL
            ? URL.revokeObjectURL(url)
            : window.URL.revokeObjectURL(url);
          link.remove();
          setIsDownloading(false);
        }, 100); // Adjust timeout as needed
      };

      return isPending ? (
        <Box textAlign="center">
          <CircularProgress size={20} />
        </Box>
      ) : (
        !isError && (
          <Box>
            <Box
              onClick={handleClickOpen}
              sx={{ position: "relative", cursor: "pointer" }}
            >
              <img
                src={`data:${articleImage.file_type};base64,${articleImage.content}`}
                style={{ width: "100%", display: "block" }}
                alt="Click pentru a mări imaginea"
              />
              <ArrowsPointingOutIcon
                width={24}
                style={{
                  position: "absolute",
                  right: theme.spacing(1.5),
                  bottom: theme.spacing(1.5),
                  color: theme.palette.primary.black,
                }}
              />
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              gap={3}
              sx={{
                marginTop: theme.spacing(2),
              }}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowDownTrayIcon width={20} />}
                sx={{ paddingX: { xs: 1.5, sm: 3 } }}
                disabled={isDownloading}
                onClick={downloadImage}
              >
                {isDownloading ? "Se descarcă..." : "Descarcă imaginea"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowsPointingOutIcon width={20} />}
                sx={{ paddingX: { xs: 1.5, sm: 3 } }}
                onClick={handleClickOpen}
              >
                Mărește imaginea
              </Button>
            </Stack>
            <Dialog
              open={open}
              onClose={handleClose}
              disableBackdropClick={false}
              fullScreen
              scroll="paper"
              PaperProps={{
                sx: {
                  background: "rgba(0,0,0,0.5)",
                  backdropFilter: "blur(4px)",
                },
              }}
            >
              <DialogTitle minHeight={72}>
                <IconButton
                  color="primary"
                  onClick={handleClose}
                  sx={{
                    position: "fixed",
                    right: theme.spacing(2),
                    top: theme.spacing(2),
                    background: theme.palette.secondary.dark,
                    "&:hover": {
                      background: theme.palette.secondary.darker,
                    },
                  }}
                  title="Închide imaginea"
                >
                  <XMarkIcon
                    width={24}
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </IconButton>
              </DialogTitle>
              <DialogContent
                sx={{
                  border: "none",
                }}
              >
                <img
                  src={`data:${articleImage.file_type};base64,${articleImage.content}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "block",
                    objectFit: "contain",
                    // height: "fit-content",
                  }}
                  alt="Imagine"
                />
              </DialogContent>
            </Dialog>
          </Box>
        )
      );
    };

    const ArticleMaterialPDF = ({ imageID }) => {
      const { data: filePDF, isError, isPending } = GetMedia(imageID);

      const [numPages, setNumPages] = useState();
      const [pageNumber, setPageNumber] = useState(1);
      const [pdfData, setPdfData] = useState(null);

      function onDocumentLoadSuccess({ numPages }) {
        // console.log(numPages);
        setNumPages(numPages);
      }

      const navigatePage = (offset = 1) => {
        setPageNumber((prev) => prev + offset);
      };

      useEffect(() => {
        // console.log(filePDF?.content);
        const decodeContent = async () => {
          if (filePDF?.content) {
            // const base64Content = atob(filePDF.content);
            const buffer = new Buffer(filePDF.content, "base64");
            // console.log(buffer);
            setPdfData(buffer);
          }
        };
        decodeContent();
      }, [filePDF]);

      const file = useMemo(() => ({ data: pdfData }), [pdfData]);

      // DOWNLOAD
      const [isDownloading, setIsDownloading] = useState(false);

      const downloadPDF = () => {
        setIsDownloading(true);
        // const base64Content = atob(filePDF.content);
        const buffer = new Buffer(filePDF.content, "base64");
        const blob = new Blob([buffer], { type: filePDF.file_type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filePDF.file_name;
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          link.remove();
          setIsDownloading(false);
        }, 100); // Adjust timeout as needed
      };

      return isPending ? (
        <Box textAlign="center">
          <CircularProgress size={20} color="secondary" />
        </Box>
      ) : (
        !isError && pdfData && (
          <>
            <Box className="material-pdf">
              <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                loading="Se încarcă documentul..."
                error="A intervenit o eroare la afișarea documentului."
                noData="A intervenit o eroare la afișarea documentului."
              >
                <Page
                  pageNumber={pageNumber}
                  canvasBackground={
                    "#fff"
                    // theme.palette.primary.black
                  }
                  renderTextLayer={false}
                />

                {/* {Array.from(new Array(numPages), (el, index) => (
                  <Box
                    key={`page-${index + 1}`}
                    style={{
                      // display: pageNumber === index + 1 ? "block" : "none",
                      opacity: pageNumber === index + 1 ? 1 : 0,
                    }}
                  >
                    <Page pageNumber={index + 1} />
                  </Box>
                ))} */}
              </Document>
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
              sx={{
                marginTop: theme.spacing(2),
                // marginBottom: theme.spacing(-1),
              }}
            >
              <Typography
                variant="body3"
                sx={{
                  display: { xs: "block", sm: "none" },
                  color: theme.palette.primary.gray2,
                }}
              >
                Pag. {pageNumber}/{numPages}
              </Typography>
              <Typography
                variant="body3"
                sx={{
                  display: { xs: "none", sm: "block" },
                  color: theme.palette.primary.gray2,
                }}
              >
                Pagina {pageNumber} din {numPages}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowDownTrayIcon width={20} />}
                sx={{ paddingX: { xs: 1.5, sm: 3 } }}
                disabled={isDownloading}
                onClick={downloadPDF}
              >
                {isDownloading ? "Se descarcă..." : "Descarcă PDF"}
              </Button>
              <Stack direction="row" alignItems="center" spacing={1}>
                <IconButtonOutlined
                  disabled={pageNumber === 1}
                  onClick={() => navigatePage(-1)}
                  sx={{
                    color: `${
                      pageNumber === 1
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.dark
                    } !important`,
                    borderColor: `${
                      pageNumber === 1
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.dark
                    } !important`,
                  }}
                >
                  <ArrowLeftIcon width={18} />
                </IconButtonOutlined>
                <IconButtonOutlined
                  disabled={pageNumber === numPages}
                  onClick={() => navigatePage(1)}
                  sx={{
                    color: `${
                      pageNumber === numPages
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.dark
                    } !important`,
                    borderColor: `${
                      pageNumber === numPages
                        ? theme.palette.secondary.main
                        : theme.palette.secondary.dark
                    } !important`,
                  }}
                >
                  <ArrowRightIcon width={18} />
                </IconButtonOutlined>
              </Stack>
            </Stack>
          </>
        )
      );
    };

    return (
      <Box
        sx={{
          padding: {
            xs: theme.spacing(3, 2, 4, 2),
            sm: theme.spacing(3, 4, 4, 4),
          },
          marginX: {
            xs: -2,
            sm: 0,
          },
          borderRadius: "0.75rem",
          position: "relative",
          overflow: "hidden",
          "&:before": {
            content: "''",
            position: "absolute",
            zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "rgba(255, 255, 255, 0.25)",
            mixBlendMode: "overlay",
            backdropFilter: "blur(40px)",
            display: { xs: "none", sm: "block" },
          },
        }}
      >
        <Stack spacing={2}>
          <Box>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 400,
                lineHeight: 1.3,
                marginBottom: `${theme.spacing(1)} !important`,
                fontSize: {
                  sm: "1.5rem",
                  lg: "2rem",
                },
              }}
            >
              {article.title}
            </Typography>
            {article.subtitle && (
              <Typography
                variant="h2"
                sx={{
                  fontSize: "1.25rem",
                  color: "primary.darker",
                  marginBottom: "0 !important",
                }}
              >
                {article.subtitle}
              </Typography>
            )}
          </Box>
          <Stack
            direction="row"
            alignItems="stretch"
            spacing={2}
            divider={
              <Box
                sx={{
                  width: "0.5px",
                  heigth: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.125)",
                }}
              />
            }
            sx={{
              marginX: `-${theme.spacing(4)} !important`,
              paddingX: 4,
              borderTop: "0.5px solid rgba(255, 255, 255, 0.125)",
              borderBottom: "0.5px solid rgba(255, 255, 255, 0.125)",
              opacity: 0.8,
              background: "rgba(255, 255, 255, 0.10)",
            }}
          >
            {article.speaker && (
              <Box flexGrow={1} paddingY={0.5}>
                <Typography variant="body3" sx={{ color: "primary.darker" }}>
                  Lector:{" "}
                </Typography>
                <Typography variant="body3" sx={{ color: "primary.main" }}>
                  {article.speaker}
                </Typography>
              </Box>
            )}
            <Box paddingY={0.5}>
              <Typography
                variant="body3"
                sx={{
                  color: "primary.darker",
                  display: { xs: "block", sm: "inline-block" },
                }}
              >
                Publicat:
              </Typography>{" "}
              <Typography variant="body3" sx={{ color: "primary.main" }}>
                {format(new Date(article.publish_date), "dd MMMM yyyy", {
                  locale: ro,
                })}
              </Typography>
            </Box>
            {article.duration && (
              <Box paddingY={0.5}>
                <Typography
                  variant="body3"
                  sx={{
                    color: "primary.darker",
                    display: { xs: "block", sm: "inline-block" },
                  }}
                >
                  Timp de lectură:
                </Typography>{" "}
                <Typography variant="body3" sx={{ color: "primary.main" }}>
                  {article.duration}
                </Typography>
              </Box>
            )}
          </Stack>
          {article.description && (
            <Box marginBottom={2}>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: articleContentSanitized(article.description),
                }}
              />
            </Box>
          )}
          <Box
            id="pdf-content-main"
            sx={{
              lineHeight: 1.5,
              "& a": {
                color: "primary.main",
              },
            }}
          >
            <ArticleContentHTML
              content={articleContentSanitized(getArticleContent())}
            />
          </Box>
          {article?.generate_pdf === 1 && (
            <ArticleGeneratePDF article={article} />
          )}
          {article.type === "video" && <ArticleVideoPlayer article={article} />}
          {article.materials?.length > 0 && (
            <Box>
              {article.materials
                .filter((item) => item.type === "image")
                .map((image, index) => (
                  <Box
                    key={`image-${index}`}
                    padding={{ xs: 2, sm: 3 }}
                    marginX={{ xs: -2, sm: 0 }}
                    sx={{
                      background: theme.palette.primary.black,
                    }}
                  >
                    {image.resource_ids.map((imageId, indexImage) => (
                      <ArticleMaterialImage
                        key={`image-${index}-${indexImage}`}
                        imageID={imageId}
                      />
                    ))}
                  </Box>
                ))}
              {article.materials
                .filter((item) => item.type === "pdf")
                .map((document, index) => (
                  <Box
                    key={`pdf-${index}`}
                    padding={{ xs: 2, sm: 3 }}
                    marginX={{ xs: -2, sm: 0 }}
                    sx={{
                      // background: theme.palette.primary.black,
                      background: "#fff",
                    }}
                  >
                    {document.resource_ids.map((documentId, indexDocument) => (
                      <ArticleMaterialPDF
                        key={`pdf-${index}-${indexDocument}`}
                        imageID={documentId}
                      />
                    ))}
                  </Box>
                ))}
            </Box>
          )}
          <Box
            sx={{
              width: "100%",
              height: "0.5px",
              background: "rgba(255, 255, 255, 0.125)",
            }}
          />
          <Typography variant="body2">{article.approval_code}</Typography>
        </Stack>
      </Box>
    );
  };

  const ArticleScrollTop = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        marginTop={2}
      >
        <Box
          onClick={() => {
            topRef.current.scrollIntoView({
              behavior: "smooth",
            });
          }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            cursor: "pointer",
            padding: 2,
            transition: "all 200ms ease-in-out",
            "&:hover": {
              "& div:first-of-type": {
                background:
                  "linear-gradient(180deg, rgba(235, 212, 247, 0.24) 0%, rgba(235, 212, 247, 0.08) 100%)",
              },
              "& div:nth-of-type(1)": {
                top: -15,
              },
            },
          }}
        >
          <Box
            sx={{
              borderRadius: "1.5rem",
              border: "0.5px solid rgba(255, 255, 255, 0.48)",
              boxSizing: "border-box",
              background:
                "linear-gradient(180deg, rgba(235, 212, 247, 0.12) 0%, rgba(235, 212, 247, 0.04) 100%)",
              padding: theme.spacing(1, 0.5),
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              transition: "all 400ms ease-in-out",
              top: 0,
            }}
          >
            <ArrowLongUpIcon width={18} />
          </Box>
          <Box paddingTop={0.5}>
            <Typography component="span">Mergi sus</Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return therapeuticAreasContent && article !== null && product !== null ? (
    article !== undefined && product !== undefined ? (
      <Container
        ref={topRef}
        maxWidth="xl"
        sx={{
          position: "relative",
          paddingBottom: {
            xs: "80px",
            sm: "170px",
          },
          // height: "100%",
          minHeight: "100%",
          flexGrow: 1,
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowLongLeftIcon width={20} />}
          to={`/therapeutic-areas/${therapeuticAreaSlug}/${therapeuticAreaIndicationSlug}/${brandSlug}`}
          sx={{ marginY: 2 }}
          component={RouterLink}
        >
          Înapoi
        </Button>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <ArticleBrand />
            <ArticleContent article={article} />
            <ArticleScrollTop />
            {/* <Box marginBottom={7} sx={{ display: { xs: "none", md: "block" } }}>
              <ArticlesSidebar />
            </Box> */}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: "sticky",
                top: "70px",
                alignSelf: "flex-start",
              }}
            >
              <ArticlesSidebar />
            </Box>
          </Grid>
        </Grid>
      </Container>
    ) : (
      <Error404 />
    )
  ) : null;
};

export default ArticleInfo;
