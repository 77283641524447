import React from "react";
import { Box, Button } from "@mui/material";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { format } from "date-fns";

const ArticleGeneratePDF = ({ article }) => {
  const generatePDF = async () => {
    const elementMain = document.getElementById("pdf-content-main");
    const elementHTML = document.getElementById("pdf-content");

    const element = elementHTML || elementMain;

    if (!element) {
      console.error("No element found to generate the PDF.");
      return;
    }

    try {
      // Ensure all fonts are loaded before capturing the canvas
      await document.fonts.ready;

      // Clone the element
      const clone = element.cloneNode(true);

      // Apply a fixed width to the clone for consistent rendering
      clone.style.width = window.innerWidth < 900 ? "800px" : "1000px";
      clone.style.margin = "0 auto";
      clone.style.overflow = "visible";
      clone.style.backgroundColor = "white";
      clone.style.color = "black";

      // Temporarily append the clone to the DOM but keep it hidden
      clone.style.position = "absolute";
      clone.style.top = "-9999px";
      document.body.appendChild(clone);

      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const padding = 15; // Padding for content
      const usableHeight = pageHeight - 2 * padding; // Usable area for content

      // Capture the clone as a canvas
      const canvas = await html2canvas(clone, {
        scale: 2, // High resolution
        useCORS: true, // Handle cross-origin resources
        backgroundColor: "#ffffff", // Ensure a solid background
      });

      // Remove the clone from the DOM
      document.body.removeChild(clone);

      const contentHeight = canvas.height;
      const contentWidth = canvas.width;

      // Scale canvas content to fit within the PDF page width
      const scaledWidth = pageWidth - 2 * padding;

      let yOffset = 0; // Tracks the vertical position in the content
      let isContentRendered = false; // Flag to track whether content was added to the PDF

      while (yOffset < contentHeight) {
        const sliceHeight = Math.min(
          contentHeight - yOffset,
          usableHeight * (contentWidth / scaledWidth)
        );

        const pageCanvas = document.createElement("canvas");
        pageCanvas.width = contentWidth;
        pageCanvas.height = sliceHeight;

        const pageContext = pageCanvas.getContext("2d");
        pageContext.drawImage(
          canvas,
          0,
          yOffset,
          contentWidth,
          sliceHeight,
          0,
          0,
          contentWidth,
          sliceHeight
        );

        const imgData = pageCanvas.toDataURL("image/png");

        pdf.addImage(
          imgData,
          "PNG",
          padding,
          padding,
          scaledWidth,
          (sliceHeight * scaledWidth) / contentWidth
        );

        yOffset += sliceHeight;
        isContentRendered = true;

        if (yOffset < contentHeight) {
          pdf.addPage();
        }
      }

      if (isContentRendered) {
        const filename = `${format(new Date(), "yyyy-MM-dd_HH:mm")}_${
          article.slug
        }.pdf`;
        pdf.save(filename);
      } else {
        console.error("No content rendered into the PDF.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <Box textAlign="center">
      <Button
        variant="contained"
        color="primary"
        startIcon={<DocumentTextIcon width={20} />}
        sx={{ paddingX: 3 }}
        onClick={generatePDF}
      >
        Generează PDF
      </Button>
    </Box>
  );
};

export default ArticleGeneratePDF;
